import * as RadixPopover from '@radix-ui/react-popover';

import Content from './components/content';

const Popover = {
  Root: RadixPopover.Root,
  Portal: RadixPopover.Portal,
  Arrow: RadixPopover.Arrow,
  Content,
  Trigger: RadixPopover.Trigger,
};

export default Popover;
